<template>
  <div>
    <div
      class="intro-container row-24 m-0 items-center"
      :style="backgroundImgUrl ? {backgroundImage: `url(${backgroundImgUrl})`} : null"
      @click="scrollDown"
    >
      <h1 class="intro-title col-18 offset-3 md:col-16 md:offset-4" v-html="payload.introtext" />
      <ScrollIndicator />
    </div>
    <section class="project-section bg-white row-24 mx-0">

      <div
        class="work-container flex flex-wrap row-24 col-22 offset-1 md:col-16 md:offset-4 lg:offset-4 m-0"
      >
        <Go
          v-for="project in randomizedProjects"
          :key="project.id"
          :to="project.link"
          class="work-card col-24 sm:col-12 md:col-12 lg:col-8 m-0 flex flex-col justify-center items-center"
          @mousemove="getMousePosition"
        >
          <h2 v-html="project.title" />
          <h3 v-html="project.untertitel" />
          <div class="work-teaser" :style="{ top: mouseY + 'px', left: mouseX + 'px' }">
            <img
              :src="project.teaserbild.normal"
              :alt="project.teaserbild.alt"
              loading="lazy"
            >
          </div>

        </go>
      </div>

    </section>
  </div>
</template>

<script>
import ScrollIndicator from '../parts/ScrollIndicator.vue';

export default {
  components: {
    ScrollIndicator,
  },
  props: {
    payload: Object,
  },
  data() {
    return {
      projekte: this.payload.projekte,
      backgroundImgUrl: this.payload.introhintergrund.large,
      scrollIndicator: null,
      mouseX: 0,
      mouseY: 0,
    };
  },
  computed: {
    randomizedProjects() {
      const copiedProjects = [...this.projekte];
      return this.shuffleElements(copiedProjects);
    },
  },
  methods: {
    shuffleElements(array) {
      let currentIndex = array.length; let
        randomIndex;
      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }
      return array;
    },
    getMousePosition(event) {
      const card = event.target.getBoundingClientRect();
      const x = event.clientX - card.left;
      const y = event.clientY - card.top;
      this.mouseX = x;
      this.mouseY = y;
    },
    scrollDown() {
      window.scroll({
        top: window.innerHeight,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

.intro-title {
  &:deep(p) {
    color: var(--color-white);
  }
}

.project {
  &-section {
    padding: 8rem 0;
  }
  &-wrapper-link {
    display: inline-block;
    &:nth-child(3n + 1) {
    height: 300px;
    @screen sm {
      height: 400px;
    }
    @screen md {
      height: 500px;
    }
    @screen lg {
      height: 800px;
    }
  }
  &:nth-child(3n + 2) {
    height: 300px;
    @screen sm {
      height: 475px;
      width: 40%;
    }
    @screen md {
      height: 550px;
      width: calc(45%);
    }
    @screen lg {
      height: 500px;
      width: 33%;
    }
  }
  &:nth-child(3n) {
    height: 300px;
    @screen sm {
      height: 350px;
      width: 33%;
    }
    @screen md {
      height: 450px;
      width: 40%;
    }
    @screen lg {
      height: 650px;
      width: 25%;
    }
  }

  }
  &-teaser-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    margin: 0;
  }
  &-title {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    color: var(--color-white);
    min-width: 50%;
    max-width: 75%;
    z-index: 2;
    h2 {
      margin: 0;
    }
    :deep(p) {
      line-height: 1.2;
      font-family: var(--font-primary);
      font-size: 36px;
      @screen sm {
        font-size: 32px;
      }
      @screen md {
        font-size: 36px;
      }
      @screen lg {
        font-size: 44px;
      }
      @screen xl {
        font-size: 48px;
      }
    }
    h3 {
      font-family: var(--font-secondary);
      font-size: 18px;
      margin: 0;
      @screen sm {
        font-size: 18px;
      }
      @screen md {
        font-size: 20px;
      }
      @screen lg {
        font-size: 22px;
      }
      @screen xl {
        font-size: 24px;
      }
    }
  }
}

$spacing: 20px;

.grid {
  --cols: 1;
  @screen sm {
    --cols: 2;
  }
}

.grid-sizer,
.grid-item {
  width: calc((100% / var(--cols)) - (#{$spacing} / (var(--cols)) * (var(--cols) - 1)));
}

.grid-item {
  margin-bottom: $spacing;
}

</style>
